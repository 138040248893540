@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,600;0,700;0,800;0,900;1,500&family=Roboto:ital,wght@0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");
@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Inter", sans-serif;
}

body {
  background-color: "#FAFAFE";
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.react-datepicker__input-container {
  display: flex !important;
  flex-direction: row-reverse !important;
  align-items: center;
}

.react-datepicker-wrapper {
  width: 100%;
}

.react-datepicker__view-calendar-icon input {
  padding: 18px 30px !important;
}

.react-datepicker__calendar-icon {
  right: 23px;
}

/* react-quill css start  */
.ql-toolbar.ql-snow {
  border-color: #c3c1df !important;
  border-left: 0px !important;
  border-right: 0px !important;
  background-color: #fafafe !important;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
}

.ql-container.ql-snow {
  border: none !important;
}

.ql-editor {
  min-height: 200px;
}

.css-wmy1p7-ReactDropdownSelect:focus,
.css-wmy1p7-ReactDropdownSelect:focus-within {
  box-shadow: none !important;
}

.tooltip {
  position: relative;
  display: inline-block;
  cursor: pointer;
}

.tooltip .tooltiptext {
  visibility: hidden;
  position: absolute;
  z-index: 1;
  bottom: 160%;
  left: -570%;
  margin-left: -60px;
}

.tooltip .tooltiptext::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 12px;
  border-style: solid;
}

.tooltip .tooltiptext::before {
  content: "";
  position: absolute;
  left: 50%;
  z-index: 1;
  margin-left: -5px;
  border-width: 12px;
  border-style: solid;
  border-color: #fff transparent transparent transparent;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
}

.tooltip2 {
  position: relative;
  display: inline-block;
  cursor: pointer;
}

.tooltip2 .tooltiptext2 {
  visibility: hidden;
  position: absolute;
  z-index: 1;
  bottom: 160%;
  right: -52%;
  margin-left: -60px;
}

.tooltip2 .tooltiptext2::after {
  content: "";
  position: absolute;
  top: 100%;
  right: 2%;
  margin-left: -5px;
  border-width: 12px;
  border-style: solid;
}

.tooltip2 .tooltiptext2::before {
  content: "";
  position: absolute;
  z-index: 1;
  right: 2%;
  margin-left: -5px;
  border-width: 12px;
  border-style: solid;
  border-color: #fff transparent transparent transparent;
}

.tooltip2:hover .tooltiptext2 {
  visibility: visible;
}

.arrow {
  background-image: url(./Assets/svg/arrow.svg);
}

::-webkit-scrollbar {
  width: 0px;
}

::-webkit-scrollbar-track {
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  background-color: #d6dee1;
  border-radius: 20px;
  border: 6px solid transparent;
  background-clip: content-box;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #a8bbbf;
}

.css-1u9des2-indicatorSeparator {
  width: 0px !important;
}

@media only screen and (max-width: 1279px) {
  .tooltip .tooltiptext {
    left: auto;
    right: -52%;
  }

  .tooltip .tooltiptext::after {
    right: 2%;
    left: auto;
  }

  .tooltip .tooltiptext::before {
    right: 2%;
    left: auto;
  }
}
